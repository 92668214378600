import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {Provider} from './ApplicationContext';
import PrivateRoute from './PrivateRoute';
import Login from '../login/index';
import Login2FA from '../login/verify2fa';
import ResetPw from '../login/reset_pw';
import ForgotPw from '../login/forgot_pw';
import Dashboard from '../dashboard/index';
import DashboardForShareDaily from '../dashboard/index_forshare_daily';
import DashboardForShareWeekly from '../dashboard/index_forshare_weekly';
import ABTesting from '../abtesting/index';
import ABTestingHistory from '../abtesting/history';
import ABTestingCreation from '../abtesting/create';
import ABTestingDetails from '../abtesting/details';
import WidgetHome from '../widgets/index';
import WidgetHistory from '../widgets/history';
import WidgetDetails from '../widgets/details';
import WidgetCreate from '../widgets/builder';
import SimulateHome from '../simulate/home';
import SimulateDetailsPage from '../simulate/deatils';

import AdHome from '../ads/index';
import CreativeEdit from '../ads/creative.create';
import CampaignEdit from '../ads/builder';
import CampaignReport from '../ads/report';

import Settings from '../settings/profile';
import ABTestingGuide from '../user-guide/abtesting-guide';
import {GlobalStyle} from './App.style';

export default class extends React.Component {

  render() {
    return (
      <Provider>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route path="/login" component={Login}/>
            <PrivateRoute path="/login_2fa" component={Login2FA}/>
            <Route path="/forget_password" exact component={ForgotPw}/>
            <Route path="/reset_password" exact component={ResetPw}/>
            <PrivateRoute path="/" exact component={Dashboard}/>
            <PrivateRoute path="/dashboard" exact component={Dashboard}/>
            <PrivateRoute path="/dashboard_ta_share" exact component={DashboardForShareWeekly}/>
            <PrivateRoute path="/dashboard_ta_share_daily" exact component={DashboardForShareDaily}/>
            <PrivateRoute path="/dashboard_ta_share_weekly" exact component={DashboardForShareWeekly}/>
            <PrivateRoute path="/abtesting" exact component={ABTesting}/>
            <PrivateRoute path="/abtesting/integration-guide" exact component={ABTestingGuide}/>
            <PrivateRoute path="/abtesting/create/:abtestingId" exact component={ABTestingCreation}/>
            <PrivateRoute path="/abtesting/edit/:abtestingId" exact component={ABTestingCreation}/>
            <PrivateRoute path="/abtesting/:id" exact component={ABTestingDetails}/>
            <PrivateRoute path="/abtesting/history/:abtestingId" exact component={ABTestingHistory}/>
            <PrivateRoute path="/widget" exact component={WidgetHome}/>
            <PrivateRoute path="/widget/:id" exact component={WidgetDetails}/>
            <PrivateRoute path="/widget/history/:widgetId" exact component={WidgetHistory}/>
            <PrivateRoute path="/widget/create/:widgetId" exact component={WidgetCreate}/>
            <PrivateRoute path="/widget/edit/:widgetId" exact component={WidgetCreate}/>
            <PrivateRoute path="/sponsored_ads" exact component={AdHome}/>
            <PrivateRoute path="/sponsored_ads/creatives" exact component={() => <AdHome activeIndex={1}/>}/>
            <PrivateRoute path="/sponsored_ads/campaigns" exact component={() => <AdHome activeIndex={0}/>}/>
            <PrivateRoute path="/sponsored_ads/create_creative" exact component={CreativeEdit}/>
            <PrivateRoute path="/sponsored_ads/creative/settings" exact component={CreativeEdit}/>
            <PrivateRoute path="/sponsored_ads/campaigns/create" exact component={CampaignEdit}/>
            <PrivateRoute path="/sponsored_ads/campaigns/edit" exact component={CampaignEdit}/>
            <PrivateRoute path="/sponsored_ads/campaign/report" exact component={CampaignReport}/>

            <PrivateRoute path="/simulate/home" exact component={SimulateHome}/>
            <PrivateRoute path="/simulate/details" exact component={SimulateDetailsPage}/>
            <PrivateRoute path="/settings" exact component={Settings}/>
            <Route path="*" render={() => {
              window.location.replace('/not-found-404.html');
              return null;
            }}/>
          </Switch>
        </Router>
      </Provider>
    );
  }
}
