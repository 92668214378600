import React from 'react';
import ReactServer from 'react-dom/server';
import _ from 'lodash';
import moment from 'moment';
import {Redirect, Link} from 'react-router-dom';
import {post} from "../utils/request";
import {dashboardCards, dashboardCardsMewatch, timeseriesDimensions} from './metadata';
import {datePeriodWindows, algorithms} from '../utils/metadata';
import {SelectStyled} from '../widgets/select.style';
import SiteMapSelector from '../uikit/selectbox/siteMapSelector';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {
  ProgressBar,
  Table,
  TableScrollWrapper,
  DateSelectorDropdown,
  Transition,
  Hint,
  CondirmDialog
} from '../uikit/index';
import {CardTitle, DashboardWrapper, Card, WidgetStats, Histogram,} from './index.style';
import {KeyMetricsComparison, ContentTooltipWrapper, BarWrapper, SelectInlineStyled} from './index.style';
import dashboardMetadata from './metadata';
import {RecentActivity, TopRecommends, WidgetStatus, TopWidgets, PaginationStyled} from './index.style';
import {GridContainer} from '../uikit/grid/index.style';
import createTooltip from '../uikit/tooltip/index';
import Sparkline from './sparkline';
import {BlueJeans, colorCategory1, DarkGray, MediumGray, MediumGray1} from '../app/StyleCommon';
import Pie from './pie';
import HistogramTimeseries from './histogram.mewatch';
import Daypart from './daypart';
import PerformanceTable from './performance';
import TopABTestingTable from './abtestings.mewatch';
import {duration, filterTags, intFormatter, pct2Formatter, pctFormatter, periodFormat} from "../utils/formatter";
import {getFromCache, cacheResource} from '../utils/cache'
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import EngineBreakdown from "./engineBreakdown";
import NormalDashboard from "./index";
import {metrics} from "../utils/metadata";
import InsightExplorer from "../insights";
import ReactDOM from "react-dom";

const FILTER_CACHE_KEY = 'dashboard_settings';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const {appState: {session}} = this.props;
    this.state = {
      period: {
        start: moment().add(-1, 'day').startOf('day').toISOString(),
        end: moment().add(-1, 'day').endOf('day').toISOString()
      },
      selectedSites: ['mewatch'],
      sections: undefined,
      timeseriesMetric: 'ctr',
      widgetSortBy: 'ctr',
      widgetSortDirection: 'desc',
      widgetPage: 1,
      daypartMetric: 'impressions'
    };
  }

  componentDidMount() {
    // this.realtimeTimer = setInterval(() => {
    //   const query = this.buildInsightQuery();
    //   post('/api/queryDashboardNew', query).then(newData => (this.setState({data: newData}))).catch(e => null);
    // }, 300000);
  }

  componentWillUnmount() {
    // this.realtimeTimer && clearInterval(this.realtimeTimer);
  }

  buildInsightQuery() {
    return {...(_.omit(this.state, ['selectedSites', 'loading', 'data'])), sites: this.state.selectedSites};
  }

  render() {
    const {readOnly, appState: {session = {}} = {}} = this.props;
    const {data, error, loading, selectedSites, sections, period} = this.state;
    const {widgetSortBy,widgetSortDirection, widgetPage} = this.state;
    const {
      siteMap = [],
      keyMetrics = {},
      histogramBreakDown = {},
      engineReport = {},
      widgetReport = {},
      engineUserCount
    } = data || {};

    if(selectedSites.length !== 1 || !['mewatch', 'preprod_mewatch'].includes(selectedSites[0])) {
      return window.location.href = '/';
    }

    return (
      <MainLayout activeItem="dashboard" loading={loading} forceClose={true}>
        <DashboardWrapper>
          {
            !data && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url='/api/queryDashboardMewatch'
              params={this.buildInsightQuery()}
              successHandler={data => this.setState({data: data})}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          <div className="page-header">
            <h3>Dashboard</h3>
            <div className="filters">
              <DateSelectorDropdown
                mode='range'
                placeholder='Select Period'
                period={period}
                shortcuts={datePeriodWindows}
                historyDateAllowed={true}
                onDateRangeUpdated={period => this.setState({period: period, data: null, error: null})}/>

              <SiteMapSelector
                selected={selectedSites}
                sections={sections}
                labelPrefix={<i className="fa fa-sitemap" style={{color: DarkGray, marginRight: '10px'}}/>}
                defaultLabel={'All Sites'}
                data={siteMap}
                onChange={s => {
                  cacheResource(FILTER_CACHE_KEY, {sites: s})
                  this.setState({selectedSites: s})
                  if (s && s.length) {
                    this.setState({data: null}); //reload
                  }
                }}
                onSectionsChange={sections => {
                  console.log(sections);
                  this.setState({sections: sections, data: null})
                }}/>
            </div>
          </div>
          {
            !!error &&
            <ErrorMessage message={error}/>
          }
          {
            !!data &&
            <React.Fragment>
              <GridContainer type={'1-1-1-1-1'}>
                {!!data && dashboardCardsMewatch.map(({formatter, key, label, color, icon}) => {
                  let keyMetricData = keyMetrics[key];
                  let value = keyMetricData;
                  let trending = null;
                  if (keyMetricData && typeof keyMetricData === 'object') {
                    value = keyMetricData.value;
                    trending = keyMetricData.data;
                  }
                  return (
                    <Card className="card metric-card" key={key + value} type={color}>
                      <WidgetStats onClick={e => this.showDeeperInsights(key, 'both')}>
                        <div className="top">
                          {/*<i className={icon} aria-hidden="true"></i>*/}
                          <span>{label}</span>
                        </div>
                        <div className="middle">
                          <h3><Transition>{formatter(value)}</Transition></h3>
                          <div className="rate">
                            <Sparkline data={trending} formatter={formatter} period={period}/>
                          </div>
                        </div>
                      </WidgetStats>
                    </Card>
                  )
                })}
              </GridContainer>
              {/*<Card className="card">*/}
              {/*  <Histogram>*/}
              {/*    <CardTitle className="title-wrapper">*/}
              {/*      <span className="title">*/}
              {/*        <span>Daily Traffic </span>*/}
              {/*      </span>*/}
              {/*    </CardTitle>*/}
              {/*    <div className="middle">*/}
              {/*      {*/}
              {/*        !!histogramBreakDown &&*/}
              {/*        <HistogramTimeseries*/}
              {/*          key={histogramBreakDown.dimension + histogramBreakDown.timestamp}*/}
              {/*          dimension={histogramBreakDown.dimension}*/}
              {/*          siteMap={siteMap}*/}
              {/*          availableMetrics={[*/}
              {/*            'impressions',*/}
              {/*            'clicks',*/}
              {/*            'ctr',*/}
              {/*            'video_starts',*/}
              {/*            'video_completions',*/}
              {/*            'vcr',*/}
              {/*            'watch_time',*/}
              {/*            'bookmarks',*/}
              {/*            'uv',*/}
              {/*            'uvv',*/}
              {/*            'avg_session_duration',*/}
              {/*            'retention_rate',*/}
              {/*            'retention_rate_30days',*/}
              {/*            'subscribes',*/}
              {/*          ]}*/}
              {/*          data={histogramBreakDown}/>*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*  </Histogram>*/}
              {/*</Card>*/}
              <Card className="card">
                <CardTitle className="title-wrapper flex">
                  <span className="title">
                    <span className="blockInMobile">
                      Engine Performance
                      {!!engineUserCount &&
                        <span style={{color: MediumGray1, fontWeight: 'normal', fontSize: '12px'}}> (total <strong>{intFormatter(engineUserCount)}</strong> unique user IDs since 18 June 2024.)</span>
                      }
                      {/*<Hint pos="right"*/}
                      {/*      useFixed={true} width={200} content={*/}
                      {/*  <div style={{textAlign: 'left', color: 'white'}}>*/}
                      {/*    CTR Lift are caculated based on A/B testing against Cxense Widget.*/}
                      {/*    Click the + icon to view model level CTR Lift information.*/}
                      {/*  </div>*/}
                      {/*}/>*/}
                    </span>
                  </span>
                  <div style={{textAlign: 'right'}}>
                    <div>{periodFormat(period)}</div>
                  </div>
                </CardTitle>
                <div className="middle">
                  {
                    <EngineBreakdown
                      period={period}
                      showDeeperInsights={this.showDeeperInsights.bind(this)}
                      refreshChart={this.refreshChartWithState.bind(this)}
                      data={engineReport}/>
                  }
                </div>
              </Card>
              <Card className="card">
                <CardTitle className="title-wrapper flex">
                    <span className="title">
                      <span className="blockInMobile">
                        Widget Performance
                        {/*<Hint pos="right"*/}
                        {/*      useFixed={true} width={250} content={*/}
                        {/*  <div style={{textAlign: 'left', color: 'white'}}>*/}
                        {/*    Compare metrics within selected date range. Sort metrics to identify best and worst performing widgets.*/}
                        {/*  </div>*/}
                        {/*}/>*/}
                      </span>
                    </span>
                  <div style={{textAlign: 'right'}}>
                    <div>{periodFormat(period)}</div>
                  </div>
                </CardTitle>
                <div className="middle">
                  {<TopABTestingTable
                    key={widgetReport.timestamp}
                    data={widgetReport}
                    period={period}
                    showDeeperInsights={this.showDeeperInsights.bind(this)}
                    config={{widgetSortBy,widgetSortDirection, widgetPage}}
                    refreshChart={this.refreshChartWithState.bind(this)}/>}
                </div>
              </Card>

              {/*<Card className="card">*/}
              {/*  <CardTitle className="title-wrapper flex">*/}
              {/*      <span className="title">*/}
              {/*        <SelectInlineStyled*/}
              {/*          selected={daypartMetric}*/}
              {/*          data={_.compact([*/}
              {/*            {key: 'impressions', label: 'Impressions'},*/}
              {/*            {key: 'ctr', label: 'CTR'},*/}
              {/*            // {key: 'response_time', label: 'response_time'},*/}
              {/*            !session.isAdmin ? null : {key: 'error_rate', label: 'Timeouts'},*/}
              {/*          ])}*/}
              {/*          mobileAdjust={true}*/}
              {/*          useFixed={true}*/}
              {/*          onChange={metric => {*/}
              {/*            this.setState({daypartMetric: metric})*/}
              {/*          }}/>*/}
              {/*        <span>*/}
              {/*          Heatmap*/}
              {/*          <Hint pos="right"*/}
              {/*                useFixed={true} width={200} content={*/}
              {/*            <div style={{textAlign: 'left', color: 'white'}}>*/}
              {/*              Average hourly breakdown of the selected metric for each day of the week*/}
              {/*            </div>*/}
              {/*          }/>*/}
              {/*        </span>*/}
              {/*      </span>*/}
              {/*    <div style={{textAlign: 'right'}}>*/}
              {/*      <div>{periodFormat(period)}</div>*/}
              {/*    </div>*/}
              {/*  </CardTitle>*/}
              {/*  <div className="middle">*/}
              {/*    <Daypart*/}
              {/*      key={daypartReport.timestamp + daypartMetric}*/}
              {/*      metric={daypartMetric}*/}
              {/*      data={daypartReport}/>*/}
              {/*  </div>*/}
              {/*</Card>*/}
            </React.Fragment>
          }
        </DashboardWrapper>
      </MainLayout>
    )
  }

  showDeeperInsights(metric, engine) {
    return;
    const {data, period} = this.state;
    const metricKey = metric.replace('Stat', '');
    const metricMeta = metrics.find(m => m.key === metricKey);
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>{metricMeta.label} Insights</strong>,
      width: engine === 'both' ?  '1000px' : '500px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <div style={{overflow: 'auto', maxHeight: '80vh'}}>
          <InsightExplorer
            period={period}
            metric={metricKey}
            engine={engine}
            onConfirm={() => null}
            onCancel={() => {
              CondirmDialog.closeAll();
            }}
          />
        </div>
      )
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  refreshChartWithState(insightType, changedState) {
    this.setState(changedState, () => {
      this.refreshChart(insightType);
    });
  }

  refreshChart(insightType) {
    this.setState({loading: true});
    const query = this.buildInsightQuery();
    query.requiredInsights = [insightType];
    post('/api/queryDashboardMewatch', query).then(newData => {
      let {data: oldData} = this.state;
      this.setState({data: {...oldData, ...newData}, loading: false});
    }).catch(e => {
      this.setState({loading: false})
    });
  }
})

